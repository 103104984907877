import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import { Link } from "gatsby";
// import InputMail from "../../components/InputMail/InputMail";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper>
        <SEO title="Templates"></SEO>
        <div className="inner-banner pt-29">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">
                    Restaurant Server Checklist Template
                  </h2>
                  <p className="gr-text-8 mb-13">Last updated: June 11, 2021</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-8">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="10">
                <div className="px-lg-8 px-xl-3">
                  {/*
                  <Row className="justify-content-center text-center">
                    <Col xs={10} sm={10} md={9} lg={8} xl={7}>
                      <InputMail />
                    </Col>
                  </Row>
                  */}
                  <p className="justify-content-center text-center">
                    A free customizable checklist template to keep your
                    restaurant servers informed about their tasks
                  </p>
                  <div className="justify-content-center text-center">
                    <a
                      href="https://docs.google.com/document/d/1kxy5-DNSj04ykaRKeNVhQwJ_VEeyTaL3hMQPXPgwTXs/export?format=doc"
                      download
                      className="btn btn-primary gr-text-blue gr-text-7 font-weight-bold mt-11"
                    >
                      Download template
                    </a>
                  </div>

                  <p>
                    <Link
                      to="/templates"
                      className="btn-link with-icon-left gr-text-blue gr-text-7 font-weight-bold mt-11"
                    >
                      <i className="icon icon-tail-left font-weight-bold"></i>
                      View All Templates
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
